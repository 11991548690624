import axios from 'axios';

const instance = axios.create(
  {
    // baseURL: 'https://api.cws-main.cobold.xyz/api/',
    // baseURL: 'http://192.168.0.115:6553/api/',
    // baseURL: 'http://192.168.43.84:6553/api/',

    baseURL: 'https://api.dev.wildconnect.in/api',
    headers: {
      Accept: 'application/json',
    },
  }
);
// export const auth_url = "https://api.cws.auth.cobold.xyz/"
export const auth_url = "https://api.dev.wildconnect.in/api"

// export const auth_url = 'http://192.168.0.106:6553/api/'

// export const auth_url = 'http://192.168.43.84:6553/api/'


export default instance;
