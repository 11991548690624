import axios from 'axios';

const instance = axios.create(

  {
    baseURL: 'https://auth.dev.wildconnect.in/api/',
    // baseURL: 'https://api.cws.auth.cobold.xyz/api/',
    // baseURL: 'http://192.168.0.115:6554/api/',

    headers: {
      Accept: 'application/json',
    },
  }
);
export default instance;